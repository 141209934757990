import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import Link from '../link'
import OutlinedButton from '../outlinedButton/OutlinedButton'
import { Homepage, JMSM } from '../../utils/navLinks'

const CTAButtonRow = () => {
    const { t } = useTranslation('')

    return (
        <div className="buttonRow">
            <OutlinedButton
                label={t('homepage:title')}
                to={Homepage.path}
                // eslint-disable-next-line no-unused-vars
                component={forwardRef((props, ref) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Link {...props} />
                ))}
            />
            <OutlinedButton
                label={t('jmsm:title')}
                to={JMSM.path}
                // eslint-disable-next-line no-unused-vars
                component={forwardRef((props, ref) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Link {...props} />
                ))}
            />
        </div>
    )
}

export default CTAButtonRow
