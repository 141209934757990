import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'

const OutlinedButton = ({ label, ...props }) => {
    const { t } = useTranslation('misc')
    return (
        <Button
            size="large"
            variant="outlined"
            disableElevation
            classes={{
                root: 'outlinedButtonRoot',
                outlined: 'outlinedButtonOutlined',
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            {label || t('learnMore')}
        </Button>
    )
}

OutlinedButton.propTypes = {
    label: PropTypes.string,
}

OutlinedButton.defaultProps = {
    label: '',
}

export default OutlinedButton
